import React from "react"

import styled from "styled-components"
import { Link, graphql} from "gatsby"
import Img from 'gatsby-image'
import LayoutSlim from "../components/layouts/layoutSlim"

const tack = ({ data }) => {
  console.log(data)
  return (
    <LayoutSlim>
      <Container>
        <Img className="background" fluid={data.file.childImageSharp.fluid} />
        <div className="content">
          <h1>
            Tack för din<br></br>intresseanmälan
          </h1>
          <p>
            Vi återkommer till dig<br></br>med mer information inför
            säljstarten.
          </p>
          <a href="https://www.sundprojekt.se/">Läs mer om sundprojekt</a>
          <Link to="/">Tillbaka till startsidan</Link>
        </div>
      </Container>
    </LayoutSlim>
  )
}

const Container = styled.section`
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  
  .background {
    width: 100%;
    height:100%;
    position:absolute !important;
    z-index: 9;
    filter: brightness(0.8);
  }

  .content {
    padding: 2rem 4rem;
    background-color: #fff;
    position: relative;
    z-index: 9;

    h1 {
      font-family: "HelveticaNeue";
      font-size: calc(20px + (60 - 20) * ((100vw - 300px) / (1600 - 300)));
      line-height: calc(1em + (1.5 - 1.2) * ((100vw - 300px) / (1600 - 300)));
      text-align: center;
      margin-top: 0;
      color: rgba(44, 27, 14, 0.8);
    }

    p {
      text-align: center;
      font-size: calc(16px + (28 - 20) * ((100vw - 300px) / (1600 - 300)));
      line-height: calc(1.2em + (1.5 - 1.2) * ((100vw - 300px) / (1600 - 300)));
      margin-bottom: 1.45rem;
    }

    a {
      background: rgba(44, 27, 14, 0.8);
      display: block;
      padding: 0.8rem 2rem;
      width: 100%;
      text-decoration: none;
      font-weight: 300;
      color: #fff;
      letter-spacing: 1px solid;
      text-align: center;
      margin: 0 auto 10px auto;
      font-size: calc(16px + (25 - 20) * ((100vw - 300px) / (1600 - 300)));
      line-height: calc(1.2em + (1.5 - 1.2) * ((100vw - 300px) / (1600 - 300)));
    }
  }
  @media only screen and (max-width: 980px) {
    .content {
      padding: 2rem;
    }
  }
  @media only screen and (max-width: 500px) {
    .content {
      padding: 2rem 1rem;
      width: 90%;
    }
  }
`
export default tack

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "exterior/Exterior1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
